html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
  margin: 0;
  font-family: "Circular Std", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .ag-theme-alpine {
  --ag-font-family: "Calibri", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  --ag-font-size: 17px;
} */

.container-tag {
  position: fixed;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  color: white;
  /* border-radius: 12px; */
  overflow: hidden;
  z-index: 10;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent #608a32 transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}

.triangle h1 {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  text-align: center;
}

.oxygen-light {
  font-family: "Circular Std", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.oxygen-regular {
  font-family: "Circular Std", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.oxygen-bold {
  font-family: "Circular Std", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
